
import Vue from 'vue'
import gsap from 'gsap';
import playWhenInView from '@/modules/@common/animations/play-when-in-view';

const mainTimeline = gsap.timeline();

export default Vue.extend({
  name: 'BottomUi',
  mounted() {
    mainTimeline.add(this.animateUi(),0);
    playWhenInView(mainTimeline,`#${this.uniqueId}`)
  },
  props: {
    uniqueId:{
      type: String,
      default: 'bottom-ui',
    }
  },
  beforeDestroy() {
    mainTimeline.kill();
  },
  methods: {
    animateUi() {
      return gsap.timeline({ repeat: -1 })
      .add(gsap.timeline({ repeat: -1 })
        .delay(12)
        .to(`#${this.uniqueId} #cursor`, { x: 0, y: 0, duration: 1.2, ease: "power1.out" })
        .to(`#${this.uniqueId} #btn`, { fill: "#F5FAFF", duration: 0.2, delay: 1, ease: "power1.out" }, "<")
        .to(`#${this.uniqueId} #cursor`, { rotate: -10, scale: 0.9, duration: 0.2, ease: "power1.out" })
        .to(`#${this.uniqueId} #btn`, { fill: "#D7EAFE", duration: 0.2, ease: "power1.out" }, "<")
        .to(`#${this.uniqueId} #cursor`, { rotate: 0, scale: 1, duration: 0.2, ease: "power1.inOut" })
        .to(`#${this.uniqueId} #btn`, { fill: "#F5FAFF", duration: 0.2, ease: "power1.out" }, "<")
        .to(`#${this.uniqueId} #modal`, { opacity: 1, duration: 0.4, ease: "power1.inOut" }, "<")
        .to(`#${this.uniqueId} #cursor`, { x: -166, duration: 0.8, ease: "power1.in" })
        .to(`#${this.uniqueId} #cursor`, { opacity: 0, duration: 0.1, delay: 0.5, ease: "power1.inOut" }, "<")
        .to(`#${this.uniqueId} #writer`, { opacity: 1, duration: 0.1, ease: "power1.inOut" }, "<")
        .to(`#${this.uniqueId} #writer`, { x: -100, duration: 0.6, ease: "power1.out" }, "<")
        .to(`#${this.uniqueId} #translation`, { x: 106, duration: 1.8, ease: "steps(12)" })
        .to(`#${this.uniqueId} #writer`, { y: 120, duration: 0.8, delay: 0.5, ease: "power1.inOut" })
        .to(`#${this.uniqueId} #cursor`, { y: 120, duration: 0.8, ease: "power1.inOut" }, "<")
        .to(`#${this.uniqueId} #writer`, { opacity: 0, duration: 0.1, delay: 0.45, ease: "power1.out" }, "<")
        .to(`#${this.uniqueId} #cursor`, { opacity: 1, duration: 0.1, ease: "power1.out" }, "<")
        .to(`#${this.uniqueId} #cursor`, { rotate: -10, scale: 0.9, delay: 0.4, duration: 0.2, ease: "power1.out" })
        .to(`#${this.uniqueId} #save`, { fill: "#D7EAFE", duration: 0.3, ease: "power1.out" }, "<")
        .to(`#${this.uniqueId} #cursor`, { rotate: 0, scale: 1, duration: 0.2, ease: "power1.inOut" })
        .to(`#${this.uniqueId} #save`, { fill: "#F5FAFF", duration: 0.3, ease: "power1.out" }, "<")
        .to(`#${this.uniqueId} #modal`, { opacity: 0, duration: 0.4, delay: 0.4 ,ease: "power1.inOut" })
        .to(`#${this.uniqueId} #btn`, { fill: "#FFFFFF", duration: 0.2, ease: "power1.out" }, "<")
        .to(`#${this.uniqueId} #cursor`, { y: 180, duration: 0.4, ease: "power1.inOut" })
        .to(`#${this.uniqueId} #cursor`, { x: 188, duration: 9, ease: "power1.inOut" })
        .duration(18)
      , 0);
    }
  }
})
